import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Address:Click": {
    ya: {
      eventName: "catalog_address_click",
      page: "catalog",
      element: "address",
    },
  },
};
