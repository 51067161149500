import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";
import {
  catalogListingEvents,
} from "@magnit/unit-catalog/src/unit-catalog.events";
import {
  filterEvents,
} from "@magnit/unit-filter/src/events/filter.events";
import {
  productDetailsEvents,
} from "@magnit/unit-product-details/src/events/product-details.events";
import headerEvents from "@magnit/unit-header/src/header.events";
import common from "./common";
import modals from "./modals";
import profileEvents from "./profile";
import qrEvents from "./qr";
import catalogEvent from "./catalog";
import addressEvent from "./address";
import mainEvents from "./main";
import authEvents from "./auth";
import registerEvents from "./register";
import landingEvents from "./landing";
import couponsEvents from "./coupons";
import errorsEvents from "./errors";
import promotionsEvents from "./promotions";

export default <IAnalyticEventsConfig>{
  ...common,
  ...modals,
  ...profileEvents,
  ...qrEvents,
  ...mainEvents,
  ...catalogListingEvents,
  ...productDetailsEvents,
  ...headerEvents,
  ...filterEvents,
  ...catalogEvent,
  ...addressEvent,
  ...authEvents,
  ...registerEvents,
  ...landingEvents,
  ...couponsEvents,
  ...errorsEvents,
  ...promotionsEvents,
};
