import {
  ShopSelectShopType,
  ShopSelectShopTypeNumeric,
} from "@magnit/unit-shop-select/src/enums";
import type { IStoreSearchStore } from "~/typings/api";

export const STORE_ALLOWED_SHOP_TYPES_NUMERIC = [ShopSelectShopTypeNumeric.V1];

export const STORE_SEARCH_STRING_MIN_LENGTH = 3;
export const STORE_SEARCH_INPUT_DEBOUNCE = 500;
export const STORE_MAP_DEFAULT_CENTER = [55.755817, 37.617645];

export const STORE_DEFAULT_VALUE: IStoreSearchStore = {
  storeType: ShopSelectShopType.V1,
  name: "Виляка",
  code: "500768",
  city: "Москва",
  address: "г. Москва, г. Москва, ул. Михалковская, д. 1/51, помещ 2/1",
  latitude: 55.8364,
  longitude: 37.5376,
  openingHours: "09:00",
  closingHours: "21:00",
  pickup: false,
  pickupWorkFrom: "",
  pickupWorkTill: "",
  isPricecheckerAvailable: true,
  isPricesAndStockAvailable: false,
  isFavourite: false,
  delivery: false,
  timezone: "",
  house: "1/51, помещ 2/1",
  index: "125008",
  okrug: "Московский округ",
  region: "Москва",
  street: "Михалковская ул",
  subjectRegion: "Город федерального значения Москва",
  storeFormat: "МД/ХД",
  storeFormatCode: "hdc",
  onlineAvailable: false,
  isDistributorPickupActive: false,
  distributorWarehouseId: "",
  room: "",
  cityFiasId: "",
  coordinates: {
    latitude: 55.8364,
    longitude: 37.5376,
  },
};
