import { default as _91favoriteId_93pDWCpzvGhpMeta } from "/app/src/pages/actions/coupons/[favoriteId].vue?macro=true";
import { default as indexNTnGk18nPjMeta } from "/app/src/pages/actions/coupons/index.vue?macro=true";
import { default as indexUI3ONFaM8uMeta } from "/app/src/pages/actions/index.vue?macro=true";
import { default as _91code_935iz9tzEPzTMeta } from "/app/src/pages/actions/offers/[code].vue?macro=true";
import { default as indexCxhJf09ShsMeta } from "/app/src/pages/actions/offers/index.vue?macro=true";
import { default as _91category_93_45_91slug_933PI1pBkBIxMeta } from "/app/src/pages/catalog/[category]-[slug].vue?macro=true";
import { default as categoriesyTKCwdbKltMeta } from "/app/src/pages/categories.vue?macro=true";
import { default as contactseKkCzFBATkMeta } from "/app/src/pages/contacts.vue?macro=true";
import { default as cookie_45policyWrqZjqr6gMMeta } from "/app/src/pages/cookie-policy.vue?macro=true";
import { default as _91favoriteId_934KxbLah6vEMeta } from "/app/src/pages/coupons/[favoriteId].vue?macro=true";
import { default as index8r44U4iA8XMeta } from "/app/src/pages/coupons/index.vue?macro=true";
import { default as detailsFFSk7sT6ShMeta } from "/app/src/pages/details.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as pdnb2mfQAf3mqMeta } from "/app/src/pages/pdn.vue?macro=true";
import { default as indexo3zzLLBvpeMeta } from "/app/src/pages/product/[product]-[slug]/index.vue?macro=true";
import { default as reviewsYfy0y3jf8PMeta } from "/app/src/pages/product/[product]-[slug]/reviews.vue?macro=true";
import { default as addzGpGQBav8wMeta } from "/app/src/pages/profile/cards/add.vue?macro=true";
import { default as indexld8FuUz4C2Meta } from "/app/src/pages/profile/cards/index.vue?macro=true";
import { default as editqGTiTNmkzCMeta } from "/app/src/pages/profile/edit.vue?macro=true";
import { default as incompletepVbXbLiTn0Meta } from "/app/src/pages/profile/incomplete.vue?macro=true";
import { default as indexSJNeMkG0qzMeta } from "/app/src/pages/profile/index.vue?macro=true";
import { default as legalKiwtz7pBqgMeta } from "/app/src/pages/profile/legal.vue?macro=true";
import { default as notificationmybsV63kHzMeta } from "/app/src/pages/profile/notification.vue?macro=true";
import { default as _91oprossoId_93i9P11qt6p4Meta } from "/app/src/pages/profile/oprosso/[oprossoId].vue?macro=true";
import { default as rateu7UaGHDFElMeta } from "/app/src/pages/profile/rate.vue?macro=true";
import { default as starscxpjrL1iWpMeta } from "/app/src/pages/profile/stars.vue?macro=true";
import { default as _91id_93mshKDDNK9fMeta } from "/app/src/pages/profile/transactions/[id].vue?macro=true";
import { default as indexniqfSMMxZWMeta } from "/app/src/pages/profile/transactions/index.vue?macro=true";
import { default as profileTx35vCKuH1Meta } from "/app/src/pages/profile.vue?macro=true";
import { default as qr4xFs4J9BZ6Meta } from "/app/src/pages/qr.vue?macro=true";
import { default as indexNbUvxHm6A3Meta } from "/app/src/pages/registration/index.vue?macro=true";
import { default as search5UN5dWklNeMeta } from "/app/src/pages/search.vue?macro=true";
import { default as termsXCu4sSphCUMeta } from "/app/src/pages/terms.vue?macro=true";
import { default as todayfuO7Y3P8Z2Meta } from "/app/src/pages/today.vue?macro=true";
export default [
  {
    name: "actions-coupons-favoriteId",
    path: "/actions/coupons/:favoriteId()",
    component: () => import("/app/src/pages/actions/coupons/[favoriteId].vue").then(m => m.default || m)
  },
  {
    name: "actions-coupons",
    path: "/actions/coupons",
    component: () => import("/app/src/pages/actions/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: "actions",
    path: "/actions",
    component: () => import("/app/src/pages/actions/index.vue").then(m => m.default || m)
  },
  {
    name: "actions-offers-code",
    path: "/actions/offers/:code()",
    component: () => import("/app/src/pages/actions/offers/[code].vue").then(m => m.default || m)
  },
  {
    name: "actions-offers",
    path: "/actions/offers",
    component: () => import("/app/src/pages/actions/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-category-slug",
    path: "/catalog/:category()-:slug()",
    component: () => import("/app/src/pages/catalog/[category]-[slug].vue").then(m => m.default || m)
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/app/src/pages/categories.vue").then(m => m.default || m)
  },
  {
    name: "contacts",
    path: "/contacts",
    meta: contactseKkCzFBATkMeta || {},
    component: () => import("/app/src/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    meta: cookie_45policyWrqZjqr6gMMeta || {},
    component: () => import("/app/src/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "coupons-favoriteId",
    path: "/coupons/:favoriteId()",
    component: () => import("/app/src/pages/coupons/[favoriteId].vue").then(m => m.default || m)
  },
  {
    name: "coupons",
    path: "/coupons",
    component: () => import("/app/src/pages/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: "details",
    path: "/details",
    meta: detailsFFSk7sT6ShMeta || {},
    component: () => import("/app/src/pages/details.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login3hUYZUGxmUMeta || {},
    component: () => import("/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "pdn",
    path: "/pdn",
    meta: pdnb2mfQAf3mqMeta || {},
    component: () => import("/app/src/pages/pdn.vue").then(m => m.default || m)
  },
  {
    name: "product-product-slug",
    path: "/product/:product()-:slug()",
    component: () => import("/app/src/pages/product/[product]-[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "product-product-slug-reviews",
    path: "/product/:product()-:slug()/reviews",
    component: () => import("/app/src/pages/product/[product]-[slug]/reviews.vue").then(m => m.default || m)
  },
  {
    name: profileTx35vCKuH1Meta?.name,
    path: "/profile",
    component: () => import("/app/src/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "profile-cards-add",
    path: "cards/add",
    component: () => import("/app/src/pages/profile/cards/add.vue").then(m => m.default || m)
  },
  {
    name: "profile-cards",
    path: "cards",
    component: () => import("/app/src/pages/profile/cards/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-edit",
    path: "edit",
    component: () => import("/app/src/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: "profile-incomplete",
    path: "incomplete",
    meta: incompletepVbXbLiTn0Meta || {},
    component: () => import("/app/src/pages/profile/incomplete.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "",
    component: () => import("/app/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-legal",
    path: "legal",
    component: () => import("/app/src/pages/profile/legal.vue").then(m => m.default || m)
  },
  {
    name: "profile-notification",
    path: "notification",
    component: () => import("/app/src/pages/profile/notification.vue").then(m => m.default || m)
  },
  {
    name: "profile-oprosso-oprossoId",
    path: "oprosso/:oprossoId()",
    component: () => import("/app/src/pages/profile/oprosso/[oprossoId].vue").then(m => m.default || m)
  },
  {
    name: "profile-rate",
    path: "rate",
    component: () => import("/app/src/pages/profile/rate.vue").then(m => m.default || m)
  },
  {
    name: "profile-stars",
    path: "stars",
    component: () => import("/app/src/pages/profile/stars.vue").then(m => m.default || m)
  },
  {
    name: "profile-transactions-id",
    path: "transactions/:id()",
    meta: _91id_93mshKDDNK9fMeta || {},
    component: () => import("/app/src/pages/profile/transactions/[id].vue").then(m => m.default || m)
  },
  {
    name: "profile-transactions",
    path: "transactions",
    meta: indexniqfSMMxZWMeta || {},
    component: () => import("/app/src/pages/profile/transactions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "qr",
    path: "/qr",
    component: () => import("/app/src/pages/qr.vue").then(m => m.default || m)
  },
  {
    name: "registration",
    path: "/registration",
    meta: indexNbUvxHm6A3Meta || {},
    component: () => import("/app/src/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/app/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsXCu4sSphCUMeta || {},
    component: () => import("/app/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "today",
    path: "/today",
    component: () => import("/app/src/pages/today.vue").then(m => m.default || m)
  }
]