import Bowser from "bowser";
/*
/ веб-пуши доступны в версиях ios начиная с 16.4
*/
const PUSH_SUP_IOS_MAJOR_V = 16;
const PUSH_SUP_IOS_MINOR_V = 4;

const OLD_IOS_MAJOR_V = 15;

const getPlatformInfo = () => {
  if (typeof window === "undefined") {
    return null;
  }

  const platform = Bowser.getParser(window.navigator.userAgent);
  const os = platform.getOS();
  const browser = platform.getBrowser();
  const platformType = platform.getPlatformType();
  let pushSupportingIosVersion = false;
  let oldIosVersion = false;

  const osVersion =
    String(os.versionName || os.version).replace(/[^0-9.]/g, "") || "";
  const osName = os.name?.toLowerCase() || "";
  if (osName === "ios" && osVersion) {
    const versionNumbers = osVersion.split(".");
    const major = Number(versionNumbers[0]);
    const minor = Number(versionNumbers[1]) || 0;
    pushSupportingIosVersion = Boolean(
      major >= PUSH_SUP_IOS_MAJOR_V + 1 ||
      (major === PUSH_SUP_IOS_MAJOR_V &&
        minor &&
        minor >= PUSH_SUP_IOS_MINOR_V),
    );
    oldIosVersion =
      major < OLD_IOS_MAJOR_V || (major === OLD_IOS_MAJOR_V && minor === 0);
  }
  const browserName = browser.name?.toLowerCase() || "";

  return {
    platformType,
    osName,
    osVersion,
    pushSupportingIosVersion,
    oldIosVersion,
    browserName,
    browserVersion: browser.version || "",
    pwa: window.matchMedia("(display-mode: standalone)").matches,
    pwaLinkSupported:
      (browserName === "chrome" || browserName === "edge") &&
      osName === "android",
    mobile: ["mobile", "tablet"].includes(platformType),
  };
};

export default getPlatformInfo;
