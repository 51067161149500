import cloneDeep from "lodash.clonedeep";
import { getActivePinia, type PiniaPluginContext } from "pinia";

function resetStore({ store }: PiniaPluginContext) {
  const initialState = cloneDeep(store.$state);
  store.$reset = () => {
    store.$patch(cloneDeep(initialState));
  };
}

export default defineNuxtPlugin(() => {
  const pinia = getActivePinia();
  if (pinia) {
    pinia.use(resetStore);
  }
});
