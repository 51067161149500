import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "QR:Page:View": {
    ya: {
      eventName: "cardMain_view",
      page: "cardMain",
    },
  },
  "QR:Page:Code:View": {
    ya: {
      eventName: "cardMain_qr_view",
      page: "cardMain",
      block: "qr",
    },
  },
  "QR:Page:Number:View": {
    ya: {
      eventName: "cardMain_numCard_view",
      page: "cardMain",
      block: "numCard",
    },
  },
  "QR:Page:Number:Copy": {
    ya: {
      eventName: "cardMain_numCard_copy_click",
      page: "cardMain",
      block: "numCard",
      element: "copy",
    },
  },
  "QR:Page:Error:View": {
    ya: {
      eventName: "cardMain_error_view",
      page: "error",
    },
  },
};
