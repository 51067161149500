import { defineStore } from "pinia";
import { reactive } from "vue";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import { usePlatformStore } from "~/store/platform";

const CHECK_APP_INSTALLED_INTERVAL = 1000;
const CHECK_APP_HB_LIMIT = 10;
const DEFAULT_PWA_LINK = "/?src=pwa";

type IIosPwaError = "iosWrongOs" | "iosWrongBrowser" | "iosPwaNotInstalled";

type IIosPwaErrors = Record<IIosPwaError, boolean>;

interface IPwaState {
  installPromptBannerOpen: boolean;
  deferredInstallPrompt: any | null;
  appInstalled: boolean;
  installInProgress: boolean;
  showIosTutorial: boolean;
}

export const usePwaStore = defineStore("pwa", () => {
  const state = reactive<IPwaState>({
    installPromptBannerOpen: false,
    deferredInstallPrompt: null,
    appInstalled: false,
    installInProgress: false,
    showIosTutorial: false,
  });
  const iosErrors = reactive<IIosPwaErrors>({
    iosWrongOs: false,
    iosPwaNotInstalled: true,
    iosWrongBrowser: false,
  });
  const platformStore = usePlatformStore();
  const checkAppInstall = async (): Promise<boolean> => {
    if (typeof navigator !== "undefined" && navigator.getInstalledRelatedApps) {
      const related = await navigator.getInstalledRelatedApps();
      return related && related.length > 0;
    }
    return false;
  };
  const { send } = AnalyticManager;
  const showPrompt = async () => {
    if (state.deferredInstallPrompt) {
      addEventListener("appinstalled", () => {
        let counter = 0;
        const appCheckHeartbeat = setInterval(async () => {
          if (counter < CHECK_APP_HB_LIMIT && (await checkAppInstall())) {
            clearInterval(appCheckHeartbeat);
            state.installPromptBannerOpen = false;
            state.appInstalled = true;
            state.installInProgress = false;
          } else if (counter >= CHECK_APP_HB_LIMIT) {
            clearInterval(appCheckHeartbeat);
            state.installPromptBannerOpen = false;
            state.installInProgress = false;
          }
          counter++;
        }, CHECK_APP_INSTALLED_INTERVAL);
      });
      const promptResult = await state.deferredInstallPrompt.prompt();
      if (
        promptResult.outcome === "dismissed" &&
        typeof window !== "undefined"
      ) {
        send("App:Prompt:System:Cancel");
        window.location.reload();
      } else if (promptResult.outcome === "accepted") {
        state.installInProgress = true;
        send("App:Prompt:System:Accept");
      }
    }
  };

  watch(
    () => platformStore.platformInfo,
    (next) => {
      if (next.osName === "ios") {
        iosErrors.iosWrongOs = !next.pushSupportingIosVersion;
        iosErrors.iosWrongBrowser = next.browserName !== "safari";
        iosErrors.iosPwaNotInstalled = !next.pwa;
      } else {
        iosErrors.iosWrongOs = false;
        iosErrors.iosPwaNotInstalled = true;
        iosErrors.iosWrongBrowser = false;
      }
    },
  );

  return {
    state,
    showPrompt,
    checkAppInstall,
    DEFAULT_PWA_LINK,
  };
});
