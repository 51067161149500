import { useFetch, type UseFetchOptions } from "nuxt/app";
import type { FetchOptions } from "ofetch";
import Cookie from "@magnit/core/src/utilities/cookie";
import { useAuthStore } from "~/store/auth";
import { getBrowserInfo } from "~/utils/clientInfo";
import { storage } from "~/utils/consts";
import { getJWTExpiration, isJWTExpired, type IJWT } from "~/utils/jwt";

interface IRequestParams<T, D = T> extends UseFetchOptions<T, D> {
  gateway?: "web" | "magnit-id";
  permissions?: {
    jwt?: boolean;
  };
  headers?: FetchOptions["headers"];
  body?: any;
  params?: any;
  query?: any;
}

export const getHeaders = (
  headers: FetchOptions["headers"],
  version: string,
): HeadersInit => {
  const ksid = Cookie.get(storage.ksid);
  let userAgent = "";
  let platformVersion = "omit empty";
  const deviceId = useCookie(storage.uniqDeviceID).value || "omit empty";

  if (import.meta.client) {
    userAgent = window.navigator.userAgent;

    const browserInfo = getBrowserInfo();
    platformVersion = `${browserInfo.os} ${browserInfo.browser} ${browserInfo.version}`;
  } else {
    const headers = useRequestHeaders();
    userAgent = headers["user-agent"] || "";
  }

  return {
    ...headers,
    "Content-Type": "application/json",
    "User-Agent": userAgent,
    "X-Platform-Version": platformVersion,
    "X-Device-Tag": ksid || "disabled",
    "X-Device-ID": deviceId,
    "X-App-Version": version || "",
    "X-Device-Platform": "Web",
  };
};

const getJWT = async (): Promise<IJWT | null> => {
  if (typeof window === "undefined") {
    return null;
  }

  let JWT = Cookie.get(storage.jwt, true);

  if (!JWT) {
    return null;
  }

  const expiration = getJWTExpiration(JWT.access);

  if (expiration === null) {
    throw new Error("Invalid token");
  }

  if (isJWTExpired(expiration)) {
    const authStore = useAuthStore();
    await authStore.refresh();

    JWT = Cookie.get(storage.jwt, true);
  }

  return JWT;
};

const getPermissionJWT = async (): Promise<string> => {
  const JWT = await getJWT();

  if (!JWT) {
    throw new Error("Отсутствует JWT токен");
  }

  return `Bearer ${JWT.access}`;
};

export const useTransport = async <R = void, D = R>(
  url: string,
  params?: IRequestParams<R, D>,
  clientName?: string,
) => {
  const config = useRuntimeConfig();

  const { permissions, gateway = "web", headers, ...restParams } = params || {};

  let _headers = getHeaders(headers, config.public.version as string);

  if (gateway === "web") {
    _headers = {
      ..._headers,
      "X-Client-Name": clientName ?? "b1",
      "X-Loyalty-Type": "B1",
    };
  }

  if (permissions?.jwt) {
    const Authorization = await getPermissionJWT();

    _headers = {
      ..._headers,
      Authorization,
    };
  }

  return useFetch(url, {
    baseURL: gateway === "web" ? "/webgate" : "/magnit-id",
    headers: _headers,
    ...restParams,
  });
};
