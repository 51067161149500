import type { INotification } from "~/components/VNotification/VNotification.types";
import WarningIcon from "~/assets/svg/notifications/warning.svg";
import ErrorIcon from "~/assets/svg/notifications/error.svg";
import { useAppStore } from "~/store/app";

export default () => {
  const { notifications } = storeToRefs(useAppStore());

  const warning = (props: Partial<INotification> = {}) => {
    const notification = {
      icon: WarningIcon,
      key: Date.now(),
      ...props,
    };
    notifications.value.push(notification);
  };
  const error = (props: Partial<INotification> = {}) => {
    const notification = {
      title: "Ошибка",
      text: "Что-то пошло не так. Попробуйте чуть позже",
      icon: ErrorIcon,
      key: Date.now(),
      ...props,
    };
    notifications.value.push(notification);
  };
  return {
    warning,
    error,
  };
};
