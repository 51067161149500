import { defineStore } from "pinia";
import { reactive, computed } from "vue";

interface IPlatformInfo {
  osName: string;
  osVersion: string;
  browserName: string;
  browserVersion: string;
  platformType: string;
  pwa: boolean;
  mobile: boolean;
  pwaLinkSupported: boolean;
  pushSupportingIosVersion: boolean;
  oldIosVersion: boolean;
}

export const usePlatformStore = defineStore("platform", () => {
  const platformInfo = reactive<IPlatformInfo>({
    osName: "",
    osVersion: "",
    browserName: "",
    browserVersion: "",
    platformType: "",
    pwa: false,
    pwaLinkSupported: false,
    mobile: false,
    pushSupportingIosVersion: false,
    oldIosVersion: false,
  });

  const iosPwaWithPushSupport = computed(
    () =>
      platformInfo.pwa &&
      platformInfo.osName === "ios" &&
      platformInfo.pushSupportingIosVersion,
  );

  const isIos = computed(() => platformInfo.osName === "ios");

  const setValues = (info: IPlatformInfo) => {
    Object.assign(platformInfo, info);
  };

  return {
    platformInfo,
    isIos,
    iosPwaWithPushSupport,
    setValues,
  };
});
