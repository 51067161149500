export const DAY_NAMES = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];
export const DAY_FULL_NAMES = [
  "Понедельник",
  "Вторник",
  "Среда",
  "Четверг",
  "Пятница",
  "Суббота",
  "Воскресенье",
];

export const MONTH_NAMES = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const MONTH_PARENTAL_NAMES = [
  "Января",
  "Февраля",
  "Марта",
  "Апреля",
  "Мая",
  "Июня",
  "Июля",
  "Августа",
  "Сентября",
  "Октября",
  "Ноября",
  "Декабря",
];

// @NOTE: returns 22.10.2022
export const getFormattedDDMMYYYY = (newDate: Date): string => {
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${String(date).padStart(2, "0")}.${String(month).padStart(
    2,
    "0",
  )}.${year}`;
};

// @NOTE: returns 2022-10-22
export const getFormattedYYYYMMDD = (newDate: Date): string => {
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${year}-${String(month).padStart(2, "0")}-${String(date).padStart(
    2,
    "0",
  )}`;
};

/**
 * Преобразуем дату из строки вида "2022-10-22" в Date
 */
export const getDateFromYYYYMMDD = (date: string): Date => {
  const [yyyy, mm, dd] = date.split("-");
  return new Date(Number(yyyy), Number(mm) - 1, Number(dd));
};

/**
 * Выводим Date в виде "19 апреля"
 */
export const formatDateToDDMMMM = (date: Date): string => {
  const monthNames = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  const dd = date.getDate();
  const mm = date.getMonth();
  return `${dd} ${monthNames[mm]}`;
};

export const getWeekdayByDate = (date: Date): number => {
  const num = date.getDay();
  return !num ? 6 : num - 1;
};

export const getDiffDays = (first: Date, second?: Date) => {
  const timeDiff = Math.abs(
    (second ? second.getTime() : new Date().getTime()) - first.getTime(),
  );
  return Math.floor(timeDiff / (1000 * 3600 * 24));
};
