import type { IAnaliticEventsApi } from "@magnit/analytic-events";

export default <Record<string, IAnaliticEventsApi>>{
  "Profile:Stars:View": {
    ya: {
      eventName: "aboutStars_view",
      page: "aboutStars",
    },
  },
  "Profile:Stars:Back": {
    ya: {
      eventName: "aboutStars_back_click",
      page: "aboutStars",
      element: "back",
    },
  },
};
