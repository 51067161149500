import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";
import eventsProfileRoot from "./root";
import eventsProfileEdit from "./edit";
import eventsProfileStars from "./stars";
import eventsProfileLegal from "./legal";
import eventsProfileNotifications from "./notification";
import eventsProfileCards from "./cards";
import eventsProfileCardsAdd from "./cards-add";

export default <IAnalyticEventsConfig>{
  ...eventsProfileRoot,
  ...eventsProfileEdit,
  ...eventsProfileStars,
  ...eventsProfileLegal,
  ...eventsProfileNotifications,
  ...eventsProfileCards,
  ...eventsProfileCardsAdd,
};
