import type { RouterConfig } from "@nuxt/schema";
import { RouteNames, Routes } from "~/utils/routes";

export default <RouterConfig>{
  routes: (_routes) => [
    ..._routes,
    {
      name: RouteNames.CatalogCategory,
      path: `${Routes.Catalog}/:category`,
      component: () => import("~/pages/catalog/[category]-[slug].vue"),
    },
    {
      name: RouteNames.Catalog,
      path: Routes.Catalog,
      component: () => import("~/pages/catalog/[category]-[slug].vue"),
    },
  ],
  scrollBehavior: (to, from) => {
    if (to.path !== from.path) return { left: 0, top: 0 };
  },
};
