import type { FetchError } from "ofetch";
import { defineStore } from "pinia";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import Cookie from "@magnit/core/src/utilities/cookie";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import { useTransport, getHeaders } from "~/composables/useTransport";
import { HttpCodes, urls } from "~/api/config";
import { storage } from "~/utils/consts";
import type { IJWT } from "~/utils/jwt";
import { Routes } from "~/utils/routes";
import { useUserStore } from "~/store/user";

interface IAuthState {
  authorized: boolean;
  registered: boolean;
}

interface IAuthStatus {
  login: "initial" | "pending" | "success" | "error";
}

export const useAuthStore = defineStore("auth", () => {
  const config = useRuntimeConfig();
  const userStore = useUserStore();
  const router = useRouter();
  const { send } = AnalyticManager;

  let refreshPromise: Promise<void> | null = null;

  const state = reactive<IAuthState>({
    authorized: false,
    registered: false,
  });

  const status = reactive<IAuthStatus>({
    login: "initial",
  });

  const getMagnitId = () => Cookie.get(storage.magnitIDCode) || "";
  const setMagnitId = (code: string) => {
    Cookie.set(storage.magnitIDCode, code, { "max-age": 3600 });
  };

  const getRegister = () => state.registered;
  const setRegister = (value: boolean) => {
    state.registered = value;
  };

  const setTokens = (access: string, refresh: string) => {
    Cookie.set(storage.jwt, JSON.stringify({ access, refresh }));
    setAuth(true);
  };

  const setAuth = (value: boolean) => {
    state.authorized = value;
  };

  async function logout() {
    status.login = "initial";

    setAuth(false);
    Cookie.delete(storage.jwt);
    Cookie.delete(storage.ksid);

    await router.push(Routes.Landing);
  }

  async function login() {
    const { data, error } = await useTransport<{
      accessToken: string;
      refreshToken: string;
    }>(urls.auth.login, {
      gateway: "magnit-id",
      method: "POST",
      body: {
        aud: "V1-web",
        magnitIDCode: getMagnitId(),
      },
    });

    if (data.value) {
      setMagnitId("");
      setTokens(data.value.accessToken, data.value.refreshToken);

      // @TODO: Костылище для дополнительной регистрации юзера в CRM
      await userStore.getQR();
      status.login = "success";
      await router.push({ path: Routes.Main });
    }

    if (
      error.value?.statusCode &&
      error.value.statusCode >= HttpCodes.Error4xx
    ) {
      if (error.value.data.code) {
        send("Auth:Validation:Error:View", {
          error_type: error.value.data.code,
        });
      }

      await router.push(Routes.Profile);

      status.login = "initial";
    }
  }

  function refresh() {
    if (!refreshPromise) {
      refreshPromise = new Promise((resolve, reject) => {
        const JWT: IJWT = Cookie.get(storage.jwt, true);

        $fetch<{
          accessToken: string;
          refreshToken: string;
        }>(urls.auth.refresh, {
          baseURL: "/magnit-id",
          headers: getHeaders({}, config.public.version as string),
          method: "POST",
          body: {
            aud: "V1-web",
            refreshToken: JWT.refresh,
          },
        }).then(
          (data) => {
            if (data.accessToken) {
              setTokens(data.accessToken, data.refreshToken);
              resolve();
            }
          },
          async (error: FetchError) => {
            if (error?.statusCode) {
              if (error.statusCode === HttpCodes.Unauthorized) {
                await logout();
                return;
              }

              if (error.statusCode >= HttpCodes.Error4xx) {
                status.login = "initial";

                reject(new Error("refresh request error"));
              }
            }
          },
        );
      });
    }

    return refreshPromise.finally(() => {
      refreshPromise = null;
    });
  }

  return {
    getMagnitId,
    setMagnitId,
    getRegister,
    setRegister,
    setAuth,
    login,
    logout,
    refresh,
    state,
    status,
  };
});
