import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Catalog:Categories:Banner:Click": {
    ya: {
      eventName: "catalog_subdir_adsBanner_click",
      page: "catalog",
      block: "subdir",
      adsBanner: "adsBanner",
    },
  },
  "CardDetail:Rating:View": {
    ya: {
      eventName: "catProductReviews_view",
      page: "catProductReviews",
    },
  },
  "CardDetail:Rating:Click": {
    ya: {
      eventName: "catProduct_reviews_click",
      page: "catProductReviews",
      element: "element",
    },
  },
};
