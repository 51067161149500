import type { IAnaliticEventsApi } from "@magnit/analytic-events";

export default <Record<string, IAnaliticEventsApi>>{
  "Profile:Notification:View": {
    ya: {
      eventName: "settNotice_view",
      page: "settNotice",
    },
  },
  "Profile:Notification:Email:Toggle": {
    ya: {
      eventName: "settNotice_email_click",
      page: "settNotice",
      element: "email",
    },
  },
  "Profile:Notification:SMS:Toggle": {
    ya: {
      eventName: "settNotice_sms_click",
      page: "settNotice",
      element: "sms",
    },
  },
  "Profile:Notification:Push:Toggle": {
    ya: {
      eventName: "settNotice_push_click",
      page: "settNotice",
      element: "push",
    },
  },
  "Profile:PushOn:RequestPermissions": {
    ya: {
      eventName: "settNotice_pushPermToast_view",
      page: "settMain",
      block: "pushPermToast",
    },
  },
  "Profile:PushOn:Toast:Granted": {
    ya: {
      eventName: "settNotice_pushPermToast_ok_click",
      page: "settMain",
      block: "pushPermToast",
      element: "ok",
    },
  },
  "Profile:PushOn:Toast:Denied": {
    ya: {
      eventName: "settNotice_pushPermToast_cancel_click",
      page: "settMain",
      block: "pushPermToast",
      element: "cancel",
    },
  },
  "Profile:PushOn:Toast:Default": {
    ya: {
      eventName: "settNotice_pushPermToast_close_click",
      page: "settMain",
      block: "pushPermToast",
      element: "close",
    },
  },
};
