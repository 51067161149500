import { urls } from "./config";
import type {
  IStoreSearchAddressBody,
  IStoreSearchAddressResponse,
  IStoreSearchGeoBody,
  IStoreSearchGeoResponse,
  IStoreSearchStoreResponse,
} from "~/typings/api";

export default () => {
  const requestAddressSearch = (
    body: Partial<IStoreSearchAddressBody> = {},
  ) => {
    return useTransport<IStoreSearchAddressResponse>(
      urls["store-search"].address,
      {
        method: "POST",
        body,
      },
    );
  };
  const requestGeoSearch = (body: Partial<IStoreSearchGeoBody> = {}) => {
    return useTransport<IStoreSearchGeoResponse>(urls["store-search"].geo, {
      method: "POST",
      body,
    });
  };
  const requestStoreByCode = (code: string) => {
    // [TO DO] Метод сервиса путает местами долготу и широту магазина. Необходимо исправить на сервисе. Удалить после исправлений.
    return useTransport<IStoreSearchStoreResponse>(
      `${urls["store-search"].store}/${code}`,
    );
  };

  return {
    requestAddressSearch,
    requestGeoSearch,
    requestStoreByCode,
  };
};
