import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  // TapBar
  "TapBar:View": {
    ya: {
      eventName: "tapBar_view",
      block: "tapBar",
    },
  },
  "TapBar:Main:Click": {
    ya: {
      eventName: "tapBar_todayMain_click",
      block: "tapBar",
      element: "todayMain",
    },
  },
  "TapBar:Coupons:Click": {
    ya: {
      eventName: "tapBar_couponsMain_click",
      block: "tapBar",
      element: "couponsMain",
    },
  },
  "TapBar:QrCode:Click": {
    ya: {
      eventName: "tapBar_cardMain_click",
      block: "tapBar",
      element: "cardMain",
    },
  },
  "TapBar:Profile:Click": {
    ya: {
      eventName: "tapBar_settMain_click",
      block: "tapBar",
      element: "settMain",
    },
  },

  // MapMain
  "MapMain:View": {
    ya: {
      eventName: "mapMain_view",
      page: "mapMain",
    },
  },
  "MapMain:Store:Click": {
    ya: {
      eventName: "mapMain_store_click",
      page: "mapMain",
      element: "store",
    },
  },
  "MapMain:Geo:Click": {
    ya: {
      eventName: "mapMain_geo_click",
      page: "mapMain",
      element: "geo",
    },
  },

  // Onboarding
  "Onboarding:First:View": {
    ya: {
      eventName: "onboardingFirst_view",
      page: "onboardingFirst",
    },
  },
  "Onboarding:Second:View": {
    ya: {
      eventName: "onboardingSecond_view",
      page: "onboardingSecond",
    },
  },
  "Onboarding:Third:View": {
    ya: {
      eventName: "onboardingThird_view",
      page: "onboardingThird",
    },
  },
  "Onboarding:Continue:View": {
    ya: {
      eventName: "continue_view",
      page: null,
      element: "continue",
    },
  },
  "Onboarding:Continue:Click": {
    ya: {
      eventName: "continue_click",
      page: null,
      element: "continue",
    },
  },
  "Onboarding:Enter:View": {
    ya: {
      eventName: "enter_view",
      page: null,
      element: "enter",
    },
  },
  "Onboarding:Enter:Click": {
    ya: {
      eventName: "enter_click",
      page: null,
      element: "enter",
    },
  },

  // App Ios
  "App:Ios:Instruction:Popup:View": {
    ya: {
      eventName: "popUpPwaInstall_view",
      block: "popUpPwaInstall",
    },
  },
  "App:Ios:Instruction:Popup:Close:Click": {
    ya: {
      eventName: "popUpPwaInstall_close_click",
      block: "popUpPwaInstall",
      element: "close",
    },
  },
  // App Android
  "App:Prompt:Banner:View": {
    ya: {
      eventName: "installToast_view",
      block: "installToast",
    },
  },
  "App:Prompt:Banner:Click": {
    ya: {
      eventName: "installToast_click",
      block: "installToast",
    },
  },
  "App:Open:Banner:View": {
    ya: {
      eventName: "openToast_view",
      block: "openToast",
    },
  },
  "App:Open:Banner:Click": {
    ya: {
      eventName: "openToast_click",
      block: "openToast",
    },
  },
  "App:Prompt:System:Accept": {
    ya: {
      eventName: "installToastSys_install_click",
      block: "installToastSys",
      element: "install",
    },
  },
  "App:Prompt:System:Cancel": {
    ya: {
      eventName: "installToastSys_close_click",
      block: "installToastSys",
      element: "close",
    },
  },
};
