import { HttpCodes } from "~/api/config";
import { storage } from "~/utils/consts";
import { type IJWT } from "~/utils/jwt";
import { Routes } from "~/utils/routes";

export default defineNuxtRouteMiddleware((to, _) => {
  const jwt = useCookie<IJWT>(storage.jwt, { maxAge: APP_COOKIES_MAX_AGE });
  const magnitId = useCookie<string>(storage.magnitIDCode, {
    maxAge: APP_COOKIES_MAX_AGE,
  });
  const incompleteProfile = useCookie<string>(storage.incompleteProfile, {
    maxAge: APP_COOKIES_MAX_AGE,
  });

  if (to.path === "/profile/illegal") return navigateTo(Routes.ProfileLegal);

  if (
    to.name === String(HttpCodes.NotFound) ||
    to.path === Routes.Landing ||
    to.path === Routes.CookiePolicy
  ) {
    return;
  }

  if (!jwt.value?.access) {
    if (magnitId.value && to.path === Routes.Registration) {
      return;
    }

    if (
      to.path &&
      ![
        Routes.Login,
        Routes.Details,
        Routes.PersonalDataPolicy,
        Routes.Terms,
        Routes.Contacts,
      ].includes(to.path as Routes)
    ) {
      return navigateTo(Routes.Login);
    }
  } else {
    if (incompleteProfile.value && to.path !== Routes.ProfileIncomplete) {
      return navigateTo(Routes.ProfileIncomplete);
    }

    if (to.path === Routes.Login || to.path === Routes.Registration) {
      return navigateTo(Routes.Main);
    }
  }
});
