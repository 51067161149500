import { defineStore } from "pinia";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { useLocalStorage } from "@vueuse/core";
import Cookie from "@magnit/core/src/utilities/cookie";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import { ErrorCodes, HttpCodes, urls } from "~/api/config";
import { useTransport } from "~/composables/useTransport";
import { useAuthStore } from "~/store/auth";
import { useUserStore } from "~/store/user";
import { storage } from "~/utils/consts";
import { Routes } from "~/utils/routes";

interface IOTPState {
  phone: string;
  code: string;
  attempt: string;
  expire: number;
}

interface IStoredState {
  phone: string;
  expire: number;
}

interface IOTPStatus {
  code: "initial" | "pending" | "success" | "error";
  auth: "initial" | "pending" | "success" | "error";
  registerError?: "captcha";
  otpError?: string;
}

export const useOTPStore = defineStore("otp", () => {
  const config = useRuntimeConfig();
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const router = useRouter();
  const { send } = AnalyticManager;

  const KFP_CID = config.public.kfpCid as string;

  const state = reactive<IOTPState>({
    phone: "",
    code: "",
    attempt: "",
    expire: 0,
  });

  const savedOTPData = useLocalStorage<IStoredState>(storage.savedOTPData, {
    phone: "",
    expire: 0,
  });

  const status = reactive<IOTPStatus>({
    code: "initial",
    auth: "initial",
  });

  async function getCode(captcha?: string | null, nextPhone?: string) {
    status.code = "pending";

    if (nextPhone) {
      if (
        savedOTPData.value.phone === nextPhone &&
        Date.now() < savedOTPData.value.expire
      ) {
        state.expire = savedOTPData.value.expire;
        state.phone = savedOTPData.value.phone;
        status.code = "success";
        return;
      }

      savedOTPData.value.phone = nextPhone;
      state.phone = nextPhone;
    }

    // Получаем идентификатор сессии KFP
    const ksid = await window.kfp.login_start(KFP_CID, "login");
    if (ksid) {
      Cookie.set(storage.ksid, ksid);
    }

    const { data, error } = await useTransport<{
      attemptId: string;
      expires: string; // "2022-08-23T08:00:23Z"
      retryAfter: string; // "2022-08-23T07:59:23Z"
    }>(urls.otp.getCode, {
      gateway: "magnit-id",
      method: "POST",
      body: {
        "aud": "V1-web",
        "captcha-token": captcha || "",
        "phone": state.phone.replace(/\D/g, ""),
      },
    });

    if (data.value) {
      const expire = new Date(data.value.expires).getTime();
      savedOTPData.value.expire = expire;
      state.expire = expire;
      state.attempt = data.value.attemptId;
      status.code = "success";

      send("Auth:Success");
    }

    if (error.value) {
      const onError = () => {
        if (error.value?.data.code) {
          send("Auth:PhoneNumber:Error:View", {
            error_type: error.value.data.code,
          });
        }
      };

      if (error.value?.statusCode === HttpCodes.BadRequest) {
        status.code = "error";
        onError();
        return;
      }

      if (
        error.value?.statusCode === HttpCodes.Forbidden ||
        error.value?.data.code === ErrorCodes.Captcha
      ) {
        status.code = "initial";
        status.registerError = "captcha";
        return;
      }

      if (
        error.value?.statusCode &&
        error.value.statusCode > HttpCodes.Error4xx
      ) {
        onError();
        status.code = "initial";
      }
    }
  }

  async function checkCode(value: string) {
    if (state.code === "") {
      status.auth = "initial";
    }
    status.otpError = "";

    state.code = value;

    if (value.length === 5) {
      status.auth = "pending";

      const { data, error } = await useTransport<{
        isRegistered: boolean;
        magnitIDCode: string;
        userId: string;
      }>(urls.otp.checkCode, {
        gateway: "magnit-id",
        method: "POST",
        body: {
          aud: "V1-web",
          code: state.code,
          phone: state.phone.replace(/\D/g, ""),
          attemptId: state.attempt,
        },
      });

      if (data.value) {
        authStore.setMagnitId(data.value.magnitIDCode);
        userStore.setSavedUUID(data.value.userId);
        status.auth = "success";
        savedOTPData.value = {
          phone: "",
          expire: 0,
        };

        if (data.value.isRegistered) {
          await authStore.login();
        } else {
          await router.push(Routes.Registration);
        }
      }

      if (
        error.value?.statusCode &&
        error.value.statusCode >= HttpCodes.Error4xx
      ) {
        const onError = () => {
          if (error.value?.data.code) {
            status.otpError = error.value?.data.code;
            send("Auth:PhoneConfirm:Error:View", {
              error_type: error.value?.data.code,
            });
          }
        };

        if (
          [HttpCodes.BadRequest, HttpCodes.UnprocessableEntity].includes(
            error.value.statusCode,
          )
        ) {
          status.auth = "error";
          onError();
          return;
        }

        if (error.value.statusCode > HttpCodes.Error4xx) {
          onError();
          status.auth = "initial";
          status.otpError = "";
        }
      }
    }
  }

  return {
    state,
    status,
    getCode,
    checkCode,
  };
});
