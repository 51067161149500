import type { IStoreSearchStore } from "~/typings/api";

export const useStoresStore = defineStore("stores", () => {
  const { requestStoreByCode } = storeSearchApi();

  const selectedStoreCodeCookie = useCookie(storage.selectedStoreCode, {
    maxAge: APP_COOKIES_MAX_AGE,
  });
  const storePromptedCookie = useCookie(storage.storePrompted, {
    maxAge: APP_COOKIES_MAX_AGE,
  });

  const selectedStoreRef = ref<IStoreSearchStore>(STORE_DEFAULT_VALUE);

  const selectedStore = computed({
    get: () => selectedStoreRef.value,
    set: (v: IStoreSearchStore) => {
      selectedStoreRef.value = v;
      selectedStoreCodeCookie.value = v.code ?? STORE_DEFAULT_VALUE.code;
    },
  });
  const storePrompted = computed({
    get: () => storePromptedCookie.value ?? null,
    set: (v: string | null) => {
      storePromptedCookie.value = v ?? null;
    },
  });
  const storeShopCode = computed(() => selectedStore.value.code);

  const restoreSelectedStore = async () => {
    if (
      !selectedStoreCodeCookie.value ||
      selectedStoreCodeCookie.value === STORE_DEFAULT_VALUE.code
    ) {
      selectedStoreRef.value = { ...STORE_DEFAULT_VALUE };
    } else {
      try {
        const { data } = await requestStoreByCode(
          selectedStoreCodeCookie.value,
        );
        if (!data.value?.store) return;
        selectedStoreRef.value = data.value.store;
      } catch {
        selectedStoreRef.value = STORE_DEFAULT_VALUE;
      }
    }
  };

  const setStorePrompted = (val: "Y" | null) => {
    storePrompted.value = val;
  };

  const setSelectedStore = (store: IStoreSearchStore) => {
    selectedStore.value = store;
  };

  return {
    restoreSelectedStore,
    setStorePrompted,
    setSelectedStore,
    storeShopCode,
    selectedStore,
    storePrompted,
  };
});
