import type { IAnaliticEventsApi } from "@magnit/analytic-events";

export default <Record<string, IAnaliticEventsApi>>{
  "Profile:Edit:View": {
    ya: {
      eventName: "settProfile_view",
      page: "settProfile",
    },
  },
  "Profile:InfoBlock:View": {
    ya: {
      eventName: "settProfile_info_view",
      page: "settProfile",
      block: "info",
    },
  },
  "Profile:InfoCall:Click": {
    ya: {
      eventName: "settProfile_info_call_click",
      page: "settProfile",
      block: "info",
      element: "call",
    },
  },
  "Profile:InfoEmail:Click": {
    ya: {
      eventName: "settProfile_info_email_click",
      page: "settProfile",
      block: "info",
      element: "email",
    },
  },
  "Profile:Name:Click": {
    ya: {
      eventName: "settProfile_personal_name_click",
      page: "settProfile",
      block: "personal",
      element: "name",
    },
  },
  "Profile:Name:Save": {
    ya: {
      eventName: "settProfile_confirmName_ok_click",
      page: "settProfile",
      block: "confirmName",
      element: "ok",
    },
  },
  "Profile:Name:Cancel": {
    ya: {
      eventName: "settProfile_confirmName_back_click",
      page: "settProfile",
      block: "confirmName",
      element: "back",
    },
  },
  "Profile:Email:Click": {
    ya: {
      eventName: "settProfile_personal_email_click",
      page: "settProfile",
      block: "personal",
      element: "email",
    },
  },
  "Profile:EmailConfirm:Click": {
    ya: {
      eventName: "settProfile_confirmEmail_ok_click",
      page: "settProfile",
      block: "confirmEmail",
      element: "ok",
    },
  },
};
