<script setup lang="ts">
import { ref } from "vue";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import IllustrationWarning from "~/assets/svg/illustrations/warning.svg";
import useAnalytics from "~/composables/useAnalytics";

type IErrorPageTypes = "404" | "500";

defineProps({
  error: Object,
});

useAnalytics();
const { send } = AnalyticManager;
const handleError = () => clearError({ redirect: Routes.Main });
const errorObject = useError();
const errorType = ref<IErrorPageTypes | null>(null);

if (errorObject.value && "statusCode" in errorObject.value) {
  switch (String(errorObject.value.statusCode)) {
    case "404":
      errorType.value = "404";
      break;
    default:
      errorType.value = "500";
  }
}

onMounted(() => {
  if (errorType.value === "404") {
    const visitParams = {
      NotFoundURL: { [document.location.href]: { Реферер: document.referrer } },
    };
    send("Page:Error404:View", visitParams);
  }
});
</script>

<template>
  <div class="page-error">
    <VWrapperLocal class="page-error__wrapper">
      <IllustrationWarning />
      <VText tag="h1" font="title-large" class="page-error__title">
        {{
          errorType === "404"
            ? "Здесь ничего не нашлось"
            : "Сайт временно не работает"
        }}
      </VText>
      <VText
        tag="div"
        font="body-large-regular"
        class="page-error__description"
      >
        {{
          errorType === "404"
            ? "Посмотрите другие разделы"
            : "Мы уже чиним эту проблему — пожалуйста, попробуйте снова через 15 минут"
        }}
      </VText>
      <VButton full-width @click="handleError">
        На главную
      </VButton>
    </VWrapperLocal>
  </div>
</template>

<style scoped lang="postcss">
.page-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;

  &__wrapper {
    width: 100%;
  }

  &__title {
    margin: 8px 0;
  }

  &__description {
    margin-bottom: 24px;
  }
}
</style>
