import type { IAnaliticEventsApi } from "@magnit/analytic-events";

export default <Record<string, IAnaliticEventsApi>>{
  "Profile:View": {
    ya: {
      eventName: "settMain_view",
      page: "settMain",
    },
  },
  "Profile:EarnBlock:View": {
    ya: {
      eventName: "settMain_aboutStars_view",
      page: "settMain",
      element: "aboutStars",
    },
  },
  "Profile:EarnBlock:Click": {
    ya: {
      eventName: "settMain_aboutStars_click",
      page: "settMain",
      element: "aboutStars",
    },
  },
  "Profile:Menu:Edit:View": {
    ya: {
      eventName: "settMain_profile_view",
      page: "settMain",
      element: "profile",
    },
  },
  "Profile:Menu:Edit:Click": {
    ya: {
      eventName: "settMain_profile_click",
      page: "settMain",
      element: "profile",
    },
  },
  "Profile:Menu:Receipt:View": {
    ya: {
      eventName: "settMain_checkOnline_view",
      page: "settMain",
      element: "checkOnline",
    },
  },
  "Profile:Menu:Receipt:Click": {
    ya: {
      eventName: "settMain_checkOnline_click",
      page: "settMain",
      element: "checkOnline",
    },
  },
  "Profile:Menu:Coupons:View": {
    ya: {
      eventName: "settMain_coupons_view",
      page: "settMain",
      element: "coupons",
    },
  },
  "Profile:Menu:Coupons:Click": {
    ya: {
      eventName: "settMain_coupons_click",
      page: "settMain",
      element: "coupons",
    },
  },
  "Profile:Menu:Notifications:View": {
    ya: {
      eventName: "settMain_notice_view",
      page: "settMain",
      element: "notice",
    },
  },
  "Profile:Menu:Notifications:Click": {
    ya: {
      eventName: "settMain_notice_click",
      page: "settMain",
      element: "notice",
    },
  },
  "Profile:Menu:Cards:View": {
    ya: {
      eventName: "settMain_cards_view",
      page: "settMain",
      element: "cards",
    },
  },
  "Profile:Menu:Cards:Click": {
    ya: {
      eventName: "settMain_cards_click",
      page: "settMain",
      element: "cards",
    },
  },
  "Profile:Menu:Contacts:View": {
    ya: {
      eventName: "settMain_info_view",
      page: "settMain",
      block: "info",
    },
  },
  "Profile:Menu:Contacts:Email:Click": {
    ya: {
      eventName: "settMain_info_email_click",
      page: "settMain",
      block: "info",
      element: "email",
    },
  },
  "Profile:Menu:Contacts:Phone:Click": {
    ya: {
      eventName: "settMain_info_call_click",
      page: "settMain",
      block: "info",
      element: "call",
    },
  },
  "Profile:Menu:Contacts:ChatBot:Click": {
    ya: {
      eventName: "settMain_info_chatbot_click",
      page: "settMain",
      block: "info",
      element: "chatbot",
    },
  },
  "Profile:Menu:Social:View": {
    ya: {
      eventName: "settMain_socials_view",
      page: "settMain",
      block: "socials",
    },
  },
  "Profile:Menu:Legal:View": {
    ya: {
      eventName: "settMain_infoLegal_view",
      page: "settMain",
      element: "infoLegal",
    },
  },
  "Profile:Menu:Legal:Click": {
    ya: {
      eventName: "settMain_infoLegal_click",
      page: "settMain",
      element: "infoLegal",
    },
  },
  "Profile:Menu:Logout:View": {
    ya: {
      eventName: "settMain_LogOut_view",
      page: "settMain",
      element: "LogOut",
    },
  },
  "Profile:Menu:Logout:Click": {
    ya: {
      eventName: "settMain_LogOut_click",
      page: "settMain",
      element: "LogOut",
    },
  },
  "Profile:Social:Vk:Click": {
    ya: {
      eventName: "settMain_socials_vk_click",
      page: "settMain",
      block: "socials",
      element: "vk",
    },
  },
  "Profile:Social:Tg:Click": {
    ya: {
      eventName: "settMain_socials_tg_click",
      page: "settMain",
      block: "socials",
      element: "tg",
    },
  },
};
