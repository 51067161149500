import { usePwaStore } from "~/store/pwa";
import serviceWorkerInit from "~/sw";

const usePwa = () => {
  const pwaStore = usePwaStore();
  onBeforeMount(() => serviceWorkerInit());
  onMounted(async () => {
    addEventListener("beforeinstallprompt", (e: Event) => {
      e.preventDefault();
      pwaStore.state.deferredInstallPrompt = e;
      pwaStore.state.installPromptBannerOpen = true;
    });
    if (await pwaStore.checkAppInstall()) {
      pwaStore.state.appInstalled = true;
    }
  });
};

export default usePwa;
