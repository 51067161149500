import type { IAnaliticEventsApi } from "@magnit/analytic-events";

export default <Record<string, IAnaliticEventsApi>>{
  "Profile:Cards:View": {
    ya: {
      eventName: "cards_view",
      page: "cards",
    },
  },
  "Profile:Cards:Add": {
    ya: {
      eventName: "cards_addCard_click",
      page: "cards",
      element: "addCard",
    },
  },
};
