import type { IAnaliticEventsApi } from "@magnit/analytic-events";

export default <Record<string, IAnaliticEventsApi>>{
  "Profile:CardsAdd:View": {
    ya: {
      eventName: "addCard_view",
      page: "addCard",
    },
  },
  "Profile:CardsAdd:CardNumber:Click": {
    ya: {
      eventName: "addCard_cardNumber_click",
      page: "addCard",
      element: "cardNumber",
    },
  },
  "Profile:CardsAdd:CVC:Click": {
    ya: {
      eventName: "addCard_cvc_click",
      page: "addCard",
      element: "cvc",
    },
  },
  "Profile:CardsAdd:Guide:Click": {
    ya: {
      eventName: "addCard_whereQR_click",
      page: "addCard",
      block: "typeCard",
      element: "whereQR",
    },
  },
  "Profile:CardsAdd:Captcha:Click": {
    ya: {
      eventName: "addCard_captcha_click",
      page: "addCard",
      element: "captcha",
    },
  },
  "Profile:CardsAdd:Submit:Click": {
    ya: {
      eventName: "addCard_continue_click",
      page: "addCard",
      element: "continue",
    },
  },
  "Profile:CardsAdd:Success": {
    ya: {
      eventName: "addCardAlert_view",
      block: "addCard",
    },
  },
  "Profile:CardsAdd:Error": {
    ya: {
      eventName: "addCard_error_view",
      page: "addCard",
      block: "error",
    },
  },
  "Profile:CardsAdd:Back": {
    ya: {
      eventName: "addCard_back_click",
      page: "addCard",
      element: "back",
    },
  },
};
